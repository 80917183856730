import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './components/App'
import "bootstrap/dist/css/bootstrap.min.css"
import "mdb-react-ui-kit/dist/css/mdb.min.css"
import { DataProvider } from "./contexts/DatabaseContext"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <DataProvider>
      <App />
    </DataProvider>
  </React.StrictMode>
);

