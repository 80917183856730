import { React, useRef, useState } from "react";
import Header from "./Header"
import FooterSimple from "./FooterSimple"
import { Form, Alert } from 'react-bootstrap'
import { useNavigate } from "react-router-dom"
import emailjs from "@emailjs/browser"
import ReCAPTCHA from "react-google-recaptcha"
import {
  MDBCard,
  MDBBtn,
  MDBCardText,
  MDBCardBody
} from 'mdb-react-ui-kit'
import Netgotiate from "./Netgotiate"

export default function Contact() {
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const form = useRef()
  const recaptcha = useRef()
  const navigate = useNavigate()

  const sendEmail = (e) => {
    e.preventDefault()

    setLoading(true)

    const captchaValue = recaptcha.current.getValue()
    if (!captchaValue) {
      setError("Please verify the reCAPTCHA!");
    } else {
      // make form submission
      emailjs.sendForm('service_c44o516', 'template_zhq87ku', form.current, 'Pcmvcrm4P-6f2HDU3')
        .then((result) => {
          // show the user a success message
          navigate("/")
        }, (error) => {
          // show the user an error
          setError(error)
        })
    }

    setLoading(false)
  }

  return (
    <>
      <Header />
      <br/><br/><br/>
      <Netgotiate />
      <div className="mt-4 centerDiv">
        <MDBCard className="mt-4 mb-4 shadow-4-strong" style={{ width: "600px" }}>
          <MDBCardBody>
            <MDBCardText className="mb-2" style={{ textAlign: "center"}}>
              <h1>Contact Us</h1>
            </MDBCardText>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form ref={form} onSubmit={sendEmail}>
              <Form.Group className="mt-2"> 
                <Form.Control type="text" name="user_name" placeholder="Your Name" required />
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Control type="email" name="user_email" placeholder="Your Email" required />
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Control as="textarea" name="message" rows={3} placeholder="Message" required />
              </Form.Group>
              <MDBBtn disabled={loading} className="subButton w-80 mt-2 mb-2" type="submit">
                <b>SEND</b>
              </MDBBtn>
              <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
            </Form>
          </MDBCardBody>
        </MDBCard>
      </div>
      <FooterSimple />
    </>
  )
}
