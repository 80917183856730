import "./Harvest.css"
import React, { useRef, useState, useEffect } from 'react'
import { Form, Alert } from 'react-bootstrap'
import { useNavigate } from "react-router-dom"
import Header from "./Header"
import Footer from "./Footer"
import Netgotiate from "./Netgotiate"
import { useData } from "../contexts/DatabaseContext"
import {sha1} from 'crypto-hash'
import {
  MDBCard,
  MDBBtn,
  MDBCardBody,
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit'
import { getState } from "./Utils"
import Particles, { initParticlesEngine } from "@tsparticles/react"
import { loadSlim } from "@tsparticles/slim"
import { isMobile } from "react-device-detect"

export default function Harvest() {
  const nameRef = useRef()
  const emailRef = useRef()
  const zipRef = useRef()
  const classRef = useRef()
  const { setUserName, checkIfEmailExists, addUserInfo } = useData()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const NumberOnlyRegex = /^[0-9]*$/

  async function handleSubmit(e) {
    e.preventDefault()

    const hash = await sha1(emailRef.current.value)
    const classification = classRef.current.value === "1" ? "HomeOwner" : "Contractor / Subcontractor"
    
    if (zipRef.current.value.length !== 5) {
      setError("Your Zip must be at least 5 numbers long.")
      return
    }

    //does the email already exist?
    var emailExists = await checkIfEmailExists(hash)
    if (emailExists) {
      setError("This email is already on our list!")
      return
    }

    setUserName(nameRef.current.value)

    try {
      //if not and we are satisfied with all conditions lets submit
      setLoading(true)
      await addUserInfo(
        hash,
        nameRef.current.value, 
        emailRef.current.value, 
        zipRef.current.value, 
        getState(zipRef.current.value),
        classification
      )
    } catch {
      setError("We are unable to put you on the list at the current moment.\nPlease try again later.")
    }

    setLoading(false)
    navigate("/finish")
  }

  const [init, setInit] = useState(false)

  // this should be run only once per application lifetime
  useEffect(() => {
    if (!isMobile) {
      initParticlesEngine(async (engine) => {
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadSlim(engine);
      }).then(() => {
        setInit(true)
      })
    }
  }, [])

  const handleKeyDown = (e) => {
    e.stopPropagation()

    const isBackspace = e.key === "Backspace"

    if (!NumberOnlyRegex.test(e.key) && !isBackspace) {
        e.preventDefault()
    }

    if (!isBackspace && e.target.value.length === 5) {
      e.preventDefault()
    }
  }

  const particlesLoaded = (container) => {
    console.log(container)
  }

  const configs = {
    particles: {
      move: {
        enable: true,
        speed: { min: 1, max: 6 }
      },
      number: {
        value: 20,
        max: 30
      },
      opacity: {
        value: 1
      },
      rotate: {
        path: true
      },
      shape: {
        options: {
          image: {
            gif: true,
            height: 200,
            src: "https://particles.js.org/images/mario.gif",
            width: 200
          }
        },
        type: "image"
      },
      size: {
        value: {
          min: 32,
          max: 64
        }
      }
    }
  };

  return (
    <>
      <Header />
      <div style={{ height: "4rem" }}/>
      <Netgotiate />
      {init && <Particles id="tsparticles" particlesLoaded={particlesLoaded} options={configs} /> }
      <div style={{ display: "flex", justifyContent: "center" }}>
        <MDBCard className="mt-4 shadow-4-strong" style={{ maxWidth: "600px" }}>
          <MDBRow className='g-0'>
            <MDBCol style={{ textAlign: "center"}}>
              <b className="boldHeaderText">Under Construction</b>
              <div className="divCenter">
                <div className="divUnderline"></div>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <MDBCardBody>
                Netgotiate is building a tool that:
                <br/>
                <br/>
                <ol>
                  <li>
                    Gives homeowners peace of mind with contractors by providing transparent project pricing and quality, and
                  </li>
                  <li>
                    Connects contractors with homeowners looking for services - keeping leads flowing and bids moving.
                  </li>
                </ol>
                <b>Stay tuned by filling in our short form below.</b>
                <br/>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mt-2" id="username">
                    <Form.Control type="text" placeholder="Full Name" ref={nameRef} required />
                  </Form.Group>
                  <Form.Group className="mt-2" id="email">
                    <Form.Control type="email" placeholder="Email" ref={emailRef} required />
                  </Form.Group>
                  <Form.Group className="mt-2" id="zip">
                    <Form.Control type="number" min="00000" max="99999" onWheel="this.blur()" onKeyDown={handleKeyDown} placeholder="Zip Code" ref={zipRef} required />
                  </Form.Group>
                  <Form.Group className="mt-2" id="classification">
                    <Form.Label>I am a:</Form.Label>
                    <Form.Select ref={classRef}>
                      <option value="1">Homeowner</option>
                      <option value="2">Contractor / Subcontractor</option>
                    </Form.Select>
                  </Form.Group>
                  <div className="mx-auto" style={{ display: "flex", justifyContent: "center" }}>
                    <MDBBtn 
                      disabled={loading} 
                      className="subButton bold-text w-80 mt-4" 
                      type="submit"
                      >
                      <b style={{ fontWeight: "bold", fontSize: 24 }}>
                        SUBSCRIBE
                      </b>
                    </MDBBtn>
                  </div>
                </Form>
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBCard>
       </div>
       <br />
       <Footer />
    </>
  )
}
