import React from 'react'
import logo from '../assets/netgotiate_bottom_banner.png'

const FooterSimple = () => {
  const today = new Date()

  return (
    <>
      <div 
        className="text-center mb-4" 
        animate={{x: 0}} 
        transition={{type: "spring,tween", bounce: 0.8, duration: 2}}
        initial={{x: -400}}
      >
        <img src={logo} alt="logo" style={{ maxHeight: '50px' }} /><br/>&copy; {today.getFullYear()}
      </div>
    </>
  )
}

export default FooterSimple