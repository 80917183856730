import React from 'react'
import logo from '../assets/netgotiate_bottom_banner.png'
import { FaLinkedin, FaSquareFacebook, FaSquareInstagram, FaSquareEnvelope, FaTwitter } from "react-icons/fa6"

const Footer = () => {
  const today = new Date()

  return (
    <>
      <div className="socials">
        <a href="https://www.linkedin.com/company/101092160/"><FaLinkedin className="socialIconColor" /></a>
        <a href="https://www.facebook.com/people/Netgotiate/61554703883939/"><FaSquareFacebook className="socialIconColor" /></a>
        <a href="https://www.instagram.com/netgotiate/"><FaSquareInstagram className="socialIconColor" /></a>
        <a href="https://twitter.com/netgotiate"><FaTwitter className="socialIconColor" /></a>
        <a href="/contact"><FaSquareEnvelope className="socialIconColor" /></a>
      </div>
      <div className="text-center mb-4">
        <img src={logo} alt="logo" style={{ maxHeight: '50px' }} /><br/>&copy; {today.getFullYear()} (v1.0.13)
      </div>
    </>
  )
}

export default Footer