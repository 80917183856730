import React, { useContext, useState } from "react"
import { db } from "../firebase"
import { set, get, ref } from "firebase/database"

const DataContext = React.createContext()

export function useData() {
    return useContext(DataContext)
}

export function DataProvider({ children }) {
  const [userName, setUserName] = useState()

  function addUserInfo(hash, name, email, zip, state, classification) {
    set(ref(db, "HarvestUserInfo/" + hash), {
      userName: name,
      userEmail: email,
      userZip: zip,
      userClassification: classification,
      userState: state,
      source: "harvest-short"
    })
  }

  async function checkIfEmailExists(hash) {
    let exists = false
    const query = ref(db, "HarvestUserInfo/" + hash + "/")
    await get(query).then((snapshot) => {
      if (snapshot.exists()) {
        exists = true
      }
    }).catch((error) => {
      console.error(error)
    })
    return exists
  }

  const value = {
    userName,
    setUserName,
    addUserInfo,
    checkIfEmailExists
  }

  return (
    <DataContext.Provider value={value}>
        {children}
    </DataContext.Provider>
  )
}
