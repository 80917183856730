import { useState, useRef } from "react"
import { MDBCard, MDBCardBody, MDBCardImage, MDBRow, MDBCol} from "mdb-react-ui-kit"
import { animate, motion, useMotionValue, useMotionValueEvent } from "framer-motion"
import logo from "../assets/Color logo with background.png"

function Netgotiate() {
  const nouns = ["IMPROVEMENT.","SATISFACTION.","VALUE.","GROWTH."]
  const rootRef = useRef(null)
  const [index, setIndex] = useState(0)
  const opacity = useMotionValue(0)
  const y = useMotionValue(0)
  let isOut = false

  async function updateIndex() {
    if (isOut) {
      (index < nouns.length-1) ? setIndex(index + 1): setIndex(0)
      animate(opacity, [0, 1], { duration: 1 })
      animate(y, [20, 0], { duration: 1 })
    } else {
      animate(opacity, [1,0], { duration: 0.5, delay: 2  })
      animate(y, [0, -30], { duration: 1, delay: 2  })
    }
    isOut = !isOut
  }

  useMotionValueEvent(opacity, "animationComplete", () => {
    updateIndex()
  })

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <MDBCard className="shadow-4-strong mt-2" style={{  zIndex: "998", maxWidth: "600px" }}>
        <MDBRow className="g-0">
          <MDBCol style={{ maxWidth: "38%", alignItems: "center" }} >
            <MDBCardBody>
              <MDBCardImage className="mt-2" src={logo} alt='...' fluid/>
            </MDBCardBody>
          </MDBCol>
          <MDBCol style={{ maxWidth: "62%", alignItems: "center" }}>
              <h1 className="m-0">
                <b>INFORMATION <br/>
                +<br/>
                NEGOTIATION<br/>
                =<br/></b>
                </h1>
                <motion.div ref={rootRef}
                  style={{ opacity, y }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{type: "tween", duration: 1 }}
                  viewport={{ root: rootRef}}
                >
                  <b className="text" style={{ fontWeight: "bold" }} gradient={["to right", "#F55209, #FFA503"]}>
                    {nouns[index]}
                  </b>
                </motion.div>
          </MDBCol>
        </MDBRow>
      </MDBCard>
    </div>
  )
}
  
export default Netgotiate