import { React, useEffect } from "react"
import Footer from "./Footer"
import { useData } from "../contexts/DatabaseContext"

export default function ThankYou() {
  const { userName } = useData()

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
      document.body.scrollTop = 0
    }, 101)
  }, []);

  return (
    <>
      <div className="mt-4">
        <h1 className="text-center mb-2">Thank You{userName ? "," : "!"}</h1>
        <h1 className="text-center mb-4">{userName ? userName + "!": ""}</h1>
        <div className="text-center mb-4">You will be notified via email when the beta is ready to preview.</div>
        <Footer />
      </div>
    </>
  )
}
