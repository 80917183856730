import React from "react"
import logo from "../assets/Color logo with background.png"

function Header() {
  return (
    <div style={{
      paddingTop: "1rem",
      paddingLeft: "1rem",
      background: "white",
      position: "fixed",
      zIndex: "999",
      alignContent: "center",
      textAlign: "center",
      display: "flex",
      width: "100%",
      maxHeight: "100px"
    }}>
      <img width="40px" height="40px" src={logo} alt="..." fluid="true" /> <h3><b className="darkText">Netgotiate</b></h3>
    </div>
  )
}

export default Header;