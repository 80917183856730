import React from "react"
import Harvest from "./Harvest"
import ThankYou from "./ThankYou"
import Contact from "./Contact"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Harvest/>} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/finish" element={<ThankYou/>} />
      </Routes>
    </Router>
  )
}

export default App
